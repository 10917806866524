<template>
  <div style="background: #fff; height: 100%">
    <van-nav-bar
      style="background: #fff"
      title="VIP尊享会员招募活动"
      :fixed="true"
      :z-index="999"
    ></van-nav-bar>
    <div
      style="
        overflow: hidden;
        background: #fff;
        padding-top: 45px;
        height: 100%;
      "
    >
      <div class="hed">
        <div class="tops">
          <div class="sname">收款金额:</div>
          <div>￥{{ list.activityPrice }}</div>
        </div>
        <div class="car-number">
          <van-row class="user_links2" style="border-bottom: 1px solid #dcdcdc">
            <van-col span="5" class="chepeiib">车牌号码:</van-col>
            <van-col span="16" @click.stop="shurucp" style="float: right">
              <div
                class="item vux-1px-l"
                v-for="(v, i) in carNumberArr"
                :key="i"
              >
                <div class="flex">{{ carNumber[i] || "" }}</div>
              </div>
            </van-col>
          </van-row>
        </div>
        <van-row class="user_links" style="border-bottom: 1px solid #dcdcdc">
          <van-col span="5" class="chepeii">客户姓名:</van-col>
          <van-col span="18">
            <van-field
              v-model="addForm.customerName"
              input-align="right"
              type="text"
              placeholder="请输入客户姓名"
            />
          </van-col>
        </van-row>
        <van-row class="user_links" style="border-bottom: 1px solid #dcdcdc">
          <van-col span="5" class="chepeii">手机号码:</van-col>
          <van-col span="18">
            <van-field
              v-model="addForm.phone"
              input-align="right"
              type="tel"
              placeholder="请输入手机号码"
            />
          </van-col>
        </van-row>
        <van-row class="user_links" style="border-bottom: 1px solid #dcdcdc">
          <van-col span="5" class="chepeii" style="color: #666"
            >业务员:</van-col
          >
          <van-col span="18" style="text-align: right" v-if="qxisok">
            {{ xxuser.realName }}
          </van-col>
        </van-row>
      </div>
    </div>
    <key-board
      v-show="showKeyBoard"
      @getPlateb="getPlateb"
      v-model="carNumber"
      :car_type="type"
    ></key-board>
    <van-popup class="tanc" closeable v-model="show">
      <div class="fixss">
        <span style="color: #666">车牌号码：</span><span>{{ carNo }}</span>
      </div>
      <div class="fixss">
        <span style="color: #666">客户姓名：</span
        ><span>{{ addForm.customerName }}</span>
      </div>
      <div class="fixss">
        <span style="color: #666">手机号码：</span
        ><span>{{ addForm.phone }}</span>
      </div>
      <van-button class="botbb" type="danger" v-preventReClick @click="addlist"
        >确认办理</van-button
      >
    </van-popup>
    <div class="boto">
      <van-button
        type="danger"
        :disabled="!qxisok"
        v-preventReClick
        @click="tijiao"
        >立即办理</van-button
      >
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
import utils from "../../assets/utils";
import KeyBoard from "@/components/KeyBoard.vue";
import preventReClick from "../../assets/preventReClick";
import vueQr from "vue-qr";
export default {
  components: {
    vueQr,
    KeyBoard,
  },
  data() {
    return {
      gid: "",
      addForm: { customerName: "", phone: "" },
      carNumberArr: ["", "", "", "", "", "", "", ""],
      carNumber: [],
      qxisok: false,
      show: false,
      customerName: "",
      list: {},
      carNo: "",
      xxuser: {},
      showKeyBoard: false,
      type: "0",
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      shangjia: JSON.parse(sessionStorage.getItem("LBshangjia")),
      uid: sessionStorage.getItem("uid"),
      aid: sessionStorage.getItem("aid"),
      ispay: false,
      isWxMini: "",
    };
  },
  methods: {},
  created() {
    var isWxMini = window.__wxjs_environment === "miniprogram";
    this.isWxMini = isWxMini;
    var _this = this;
    this.aid = utils.getUrlKey("aid");
    this.gid = utils.getUrlKey("gid");
    // return
    if (this.aid) {
      sessionStorage.setItem("aid", this.aid);
    }

    if (this.gid) {
      sessionStorage.setItem("gid", this.gid);
    }

    if (sessionStorage.getItem("aid")) {
      _this.aid = sessionStorage.getItem("aid");
    }

    if (sessionStorage.getItem("gid")) {
      _this.gid = sessionStorage.getItem("gid");
    }

    console.log(localStorage.getItem("hdtoken"));
    if (localStorage.getItem("hdtoken")) {
      this.listname();
      this.username();
      var isok = localStorage.getItem("isok");
      if (isok == "true") {
        setTimeout((res) => {
          this.$router.go(0);
        }, 100);
        localStorage.removeItem("isok");
      }
    } else {
      sessionStorage.setItem("lujin", "manage");
      var appid = "wx406b62706ded5aa8";
      const redirect_uri = encodeURIComponent(
        "https://marketing.car-posthouse.cn/auth"
      );
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
    }
  },
  watch: {
    carNumber() {
      console.log(this.type);
      if (
        (this.type != 2 && this.carNumber.length === 8) ||
        this.carNumber.length === 8
      ) {
        this.showKeyBoard = false;
      }
    },
    dingshi: function (newVal, oldVal) {
      console.log(newVal);
      console.log(oldVal);
    },
    type() {
      if (this.type == 2) {
        if (this.carNumberArr.length == 7) {
          this.carNumberArr.push("");
          this.showKeyBoard = true;
        }
      } else if (this.carNumberArr.length == 8) {
        this.carNumberArr.pop();
        this.showKeyBoard = false;
      }
    },
  },
  methods: {
    shurucp() {
      console.log("asdasd");

      this.showKeyBoard = true;
    },
    getPlateb() {
      this.showKeyBoard = false;
    },
    addlist() {
      let data = {
        comboId: this.aid,
        customerName: this.addForm.customerName,
        carNo: this.carNo,
        phone: this.addForm.phone,
        comboName: this.list.activityName,
        gid: this.gid,
        sellsMan: this.xxuser.realName,
        createdBy: this.xxuser.id,
        payTypes: [
          {
            type: 2,
            amount: this.list.activityPrice,
          },
        ],
      };
      userModel.offlinesave(data).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$toast.success("办理成功！");
          this.addForm = { customerName: "", phone: "" };
          this.carNumberArr = ["", "", "", "", "", "", "", ""];
          this.carNumber = [];
          this.show = false;
        }
      });
    },
    tijiao() {
      var carNo = this.carNumber.join("");
      this.carNo = carNo;
      var vm = this;
      if (carNo == "") {
        this.$toast("请先输入车牌号码！");
        return;
      }
      if (carNo.length < 7) {
        this.$toast("请输入正确的车牌号码！");
        return;
      } else if (this.addForm.phone == "") {
        this.$toast("请先输入手机号码！");
        return;
      } else if (!/^1[23456789]\d{9}$/.test(this.addForm.phone)) {
        this.$toast("手机号码格式错误");
        return;
      } else if (this.addForm.customerName == "") {
        this.$toast("请先输入客户姓名！");
        return;
      }
      this.show = true;
    },
    username() {
      var _this = this;
      userModel.checkauth({ gid: this.gid }).then((res) => {
        if (res.code == 200) {
          if (res.data.id) {
            this.qxisok = true;
            this.xxuser = res.data;
          }
        }
      });
    },
    listname() {
      var _this = this;
      userModel.yinxiaodelete({ id: this.aid }).then((res) => {
        if (res.code == 200) {
          this.list = res.data;
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
  },
  beforeDestroy() {
    //跳转页面时关闭定时器
    if (this.timers) {
      clearInterval(this.timers);

      // console.log('asdasdsad')
    }
  },
};
</script>
<style  lang="less" scoped>
#app {
  background: #f5f5f5;
  height: 100%;
}
.hed {
  background: #fff;
  .tops {
    display: flex;
    justify-content: center;
    font-size: 44px;
    padding: 45px 0 30px 0;
    align-items: baseline;
    border-bottom: 1px solid #dcdcdc;
    color: #faa755;
    .sname {
      font-size: 14px;
      color: #666;
      margin-right: 20px;
    }
  }
}
// --------------
.car-number {
  height: 50px;
}
.item {
  border: 1px solid #013bba;
  border-radius: 2px;
  width: 24px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  float: left;
  margin-right: 5px;
}
.item:last-child {
  background: #4b84ff;
  border: 1px solid #013bba;
}
#key-board {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
}
.gzname {
  font-size: 12px;
  background: #fff;
  display: inline-block;
  padding: 1px 12px;
  color: #013bb9;
  border-radius: 6px;
  margin-bottom: 12px;
}
.gzname_a {
  font-size: 12px;
  color: #fff;
  margin-top: 10px;
}
.chepeiib {
  line-height: 34px;
  font-weight: bold;
  font-family: PingFangSC-Medium;
}
.user_links2 {
  padding: 9px 0;
  font-size: 14px;
  margin-left: 10px;
  position: relative;
}
.user_links {
  padding: 12px 0;
  font-size: 14px;
  margin-left: 10px;
  position: relative;
  border-bottom: 1px solid #dcdcdc;
}
.chepeii {
  line-height: 22px;
  font-weight: bold;
  font-family: PingFangSC-Medium;
}
.van-cell {
  padding: 0;
  // background-color: #f1f1f1;
}
// ----------------------------

.xinbie {
  width: 14px;
  float: left;
  margin-top: 11px;
  margin-left: 8px;
  color: #666;
}
.boto {
  position: fixed;
  bottom: 0;
  z-index: 99;
  left: 0;
  width: 100%;
  line-height: 40px;
  font-size: 12px;
}
.boto .van-button {
  width: 100%;
  float: left;
  border-radius: 0;
  padding: 0 6px;
}
.tanc {
  width: 280px;
  padding: 35px 20px 20px 20px;
  .fixss {
    display: flex;
    align-items: center;
    // justify-content: center;
    line-height: 40px;
    font-size: 16px;
  }
  .botbb {
    display: block;
    margin: auto;
    width: 120px;
    height: 35px;
    margin-top: 20px;
  }
}
</style>
